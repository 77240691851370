import React, { useContext, useEffect, useState } from "react";
import "./imprint-mode.step.css";
import useFormatters from "../../hooks/use-formatters";
import { StepperContext } from "../../contexts/stepper.context";
import { Steps } from "../stepper.component";
import { Alert, Box, Card, Stack, useTheme } from "@mui/material";
import { resizeBase64Png } from "../../utils";

import { ReactComponent as SignatureBuilderCardVisual } from "../../assets/SignatureBuilderCardVisual.svg";
import { ReactComponent as PNGvisual } from "../../assets/PNGvisual.svg";
import BSButton from "../button.component";

function SignatureModeStep() {
  const {
    imprintPng,
    setStep,
    setSignaturePngInput,
    setSignaturePng,
    isTouchDevice,
  } = useContext(StepperContext);
  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  const [borderColor, setBorderColor] = useState(colors.darkGrey);

  useEffect(() => {
    setSignaturePng(null);
    setSignaturePngInput(null);

    const dragoverHandler = (e: DragEvent) => {
      e.preventDefault();
    };

    const dropHandler = (e: DragEvent) => {
      e.preventDefault();
    };

    const dragleaveHandler = (e: DragEvent) => {
      e.preventDefault();
      setBorderColor(colors.darkGrey);
    };

    // Prevent dropping files anywhere else than in dropzone
    window.addEventListener("dragover", dragoverHandler);
    window.addEventListener("drop", dropHandler);
    window.addEventListener("dragleave", dragleaveHandler);

    return () => {
      window.removeEventListener("dragover", dragoverHandler);
      window.removeEventListener("drop", dropHandler);
      window.removeEventListener("dragleave", dragleaveHandler);
    };
  }, []);

  // handle image drop on import image card
  const handleImageDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setBorderColor(colors.darkGrey);

    const file = e.dataTransfer.files[0];

    if (file && file.type === "image/png") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        setSignaturePngInput(await resizeBase64Png(e.target?.result as string));
        setStep(Steps.FORM);
      };
      reader.readAsDataURL(file);
    }
  };

  // handle file input change
  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);

    if (file && file.type === "image/png") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        setSignaturePngInput(await resizeBase64Png(e.target?.result as string));
        setStep(Steps.FORM);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle dropping over zone to change border color
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setBorderColor(colors.blue);
  };

  return (
    <>
      <h1 className="title">
        {isTouchDevice
          ? formatMessage("stepper.signature.mode.titleTouchDevice")
          : formatMessage("stepper.signature.mode.title")}
      </h1>

      <Alert severity="info" style={{
        width: "50%",
        marginTop: "-20px",
        marginBottom: "-20px",
      }}>
        {formatMessage("stepper.info.import.size")}
      </Alert>

      <div className={`${isTouchDevice ? "single-mode" : "mode-layout"}`} style={{
        gridTemplateColumns: "unset"
      }}>
        {/*{!isTouchDevice && (*/}
        {/*  <div className="mode-layout__card">*/}
        {/*    <p className="text-body">*/}
        {/*      {formatMessage("stepper.signature.mode.model.card.desc")}*/}
        {/*    </p>*/}
        {/*    <Card*/}
        {/*      className="card mode-visual"*/}
        {/*      onClick={() => setStep(Steps.SIGNATURE_BUILDER)}*/}
        {/*    >*/}
        {/*      <Stack*/}
        {/*        direction={"column"}*/}
        {/*        justifyContent={"center"}*/}
        {/*        alignItems={"center"}*/}
        {/*        width={"100%"}*/}
        {/*        height={"100%"}*/}
        {/*      >*/}
        {/*        <SignatureBuilderCardVisual className="mode-visual" />*/}
        {/*        <h2>{formatMessage("stepper.signature.mode.model.card.label")}</h2>*/}
        {/*      </Stack>*/}
        {/*    </Card>*/}
        {/*  </div>*/}
        {/*)}*/}

        {/*{!isTouchDevice && (*/}
        {/*  <div className="title mode-or">{formatMessage("stepper.or")}</div>*/}
        {/*)}*/}

        <div className="mode-layout__card">
          <p className="text-body">
            {formatMessage("stepper.signature.mode.image.card.desc")}
          </p>
          <Card
            className="card mode-visual"
            onClick={() => document.getElementById("fileInput")?.click()}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleImageDrop(e)}
          >
            <Box
              border={"5px dashed " + borderColor}
              borderRadius={1}
              paddingLeft={1}
              paddingRight={1}
              paddingTop={2}
              paddingBottom={1}
              width={"100%"}
              height={"100%"}
            >
              <PNGvisual />
              <h2>{formatMessage("stepper.signature.mode.image.card.label")}</h2>
            </Box>
          </Card>
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          accept="image/png"
          onChange={(e) => handleFileInput(e)}
        />
      </div>

      <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          paddingTop={2}
      >
        <BSButton type={"reset"} onClick={() => setStep(Steps.IMPRINT_MODE)}>
          {formatMessage("stepper.button.home")}
        </BSButton>
        {imprintPng && (
            <BSButton type={"reset"} onClick={() => setStep(Steps.IMPRINT_BUILDER)}>
              {formatMessage("stepper.button.back")}
            </BSButton>
        )}
        <BSButton type={"reset"} onClick={() => setStep(Steps.FORM)}>
          {formatMessage("stepper.button.skip")}
        </BSButton>
      </Stack>
    </>
  );
}

export default SignatureModeStep;
