import React, {useContext, useEffect} from 'react';
import {StepperContext} from "../contexts/stepper.context";

import LoginStep from "./steps/login.step";
import ImprintModeStep from "./steps/imprint-mode.step";
import ImprintBuilderStep from "./steps/imprint-builder.step";
import FormStep from "./steps/form.step";
import ModelsStep from "./steps/models.step";
import ConfirmationStep from "./steps/confirmation.step";
import WelcomeModal from "./modals/welcome.modal";
import {StorageContext} from "../contexts/storage.context";
import LoginSuccessModal from "./modals/login-success.modal";
import LoginFailModal from "./modals/login-fail.modal";
import SignatureModeStep from "./steps/signature-mode.step";
import SignatureBuilderStep from "./steps/signature-builder.step";

// Enum for the steps
export enum Steps {
    LOGIN,
    IMPRINT_MODE,
    MODELS,
    IMPRINT_BUILDER,
    SIGNATURE_MODE,
    SIGNATURE_BUILDER,
    FORM,
    CONFIRMATION
}

function Stepper() {
    const {step, setStep} = useContext(StepperContext);

    const {refreshToken} = useContext(StorageContext);

    useEffect(() => {
        if (refreshToken && refreshToken !== "") {
           setStep(Steps.IMPRINT_MODE);
        }
    }, [refreshToken]);

    const renderStep = () => {
        switch (step) {
            case Steps.LOGIN:
                return <LoginStep/>;
            case Steps.IMPRINT_MODE:
                return <ImprintModeStep/>;
            case Steps.MODELS:
                return <ModelsStep/>;
            case Steps.IMPRINT_BUILDER:
                return <ImprintBuilderStep/>;
            case Steps.SIGNATURE_MODE:
                return <SignatureModeStep/>;
            case Steps.SIGNATURE_BUILDER:
                return <SignatureBuilderStep/>;
            case Steps.FORM:
                return <FormStep/>;
            case Steps.CONFIRMATION:
                return <ConfirmationStep/>;
            default:
                return <></>;
        }
    };

    return (
        <>
            {renderStep()}
            <WelcomeModal/>
            <LoginSuccessModal/>
            <LoginFailModal/>
        </>
    );
}

export default Stepper;
