import React, { useContext, useRef } from "react";

import { StepperContext } from "../../contexts/stepper.context";
import { Steps } from "../stepper.component";
import {Box, Card, IconButton, Stack, useTheme} from "@mui/material";
import BSButton from "../button.component";
import useFormatters from "../../hooks/use-formatters";
// import BSCanvasSignature, {BSCanvasSignatureMethods} from "../canvas/BSCanvasSignature";
import DeleteIcon from "@mui/icons-material/Delete";

function SignatureBuilderStep() {
  const { setStep, setSignaturePng, setDrawnSignatureCanvas, drawnSignatureCanvas } = useContext(StepperContext);
  const { formatMessage } = useFormatters();
  const theme = useTheme();

  // const bsCanvasSignatureRef = useRef<BSCanvasSignatureMethods>();

  const nextStep = async () => {
    // const data = bsCanvasSignatureRef.current?.getImageData();

    // if (data) {
    //   setSignaturePng(data);
    //   setStep(Steps.FORM);
    // }
  };

  return (
    <Stack direction="column" spacing={2} alignItems={"center"}>
      <h1 className="title">{formatMessage("stepper.builder.title")}</h1>
      <p className="text-body">{formatMessage("stepper.builder.subtitle")}</p>

      {/*<Card*/}
      {/*  style={{*/}
      {/*    marginTop: 20,*/}
      {/*    padding: "20px 20px 65px 20px",*/}
      {/*    display: "flex",*/}
      {/*    flexDirection: "column",*/}
      {/*    gap: 20,*/}
      {/*    width: "fit-content",*/}
      {/*  }}*/}
      {/*>*/}
      {/*    <Box*/}
      {/*        display="inline-block"*/}
      {/*        position="relative"*/}
      {/*        marginX="auto"*/}
      {/*        style={{*/}
      {/*            backgroundColor: "white",*/}
      {/*            borderRadius: 10,*/}
      {/*        }}*/}
      {/*    >*/}
      {/*        <BSCanvasSignature*/}
      {/*            ref={bsCanvasSignatureRef}*/}
      {/*            width={440}*/}
      {/*            height={200}*/}
      {/*            style={{*/}
      {/*                border: "1px solid",*/}
      {/*                borderColor:*/}
      {/*                    theme.palette.mode === "dark"*/}
      {/*                        ? "#41555e"*/}
      {/*                        : "#CFD8DC",*/}
      {/*                borderRadius: 10,*/}
      {/*            }}*/}
      {/*            backgroundImageB64={drawnSignatureCanvas}*/}
      {/*            onChange={() => {}}*/}
      {/*        />*/}
      {/*        <IconButton*/}
      {/*            aria-label="delete"*/}
      {/*            size="small"*/}
      {/*            sx={{ position: "absolute", top: 0, right: 0 }}*/}
      {/*            onClick={() => {*/}
      {/*                if (bsCanvasSignatureRef.current)*/}
      {/*                    bsCanvasSignatureRef.current.clearCanvas();*/}
      {/*                setDrawnSignatureCanvas(null);*/}
      {/*            }}*/}
      {/*        >*/}
      {/*            <Stack*/}
      {/*                sx={{*/}
      {/*                    backgroundColor: theme.colors.lightGrey,*/}
      {/*                    borderRadius: 0.9,*/}
      {/*                    p: 0.3,*/}
      {/*                }}*/}
      {/*            >*/}
      {/*                <DeleteIcon*/}
      {/*                    fontSize="small"*/}
      {/*                    sx={{ color: "white" }}*/}
      {/*                />*/}
      {/*            </Stack>*/}
      {/*        </IconButton>*/}
      {/*    </Box>*/}
      {/*</Card>*/}

      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        paddingTop={2}
        paddingBottom={4}
      >
        <BSButton type={"reset"} onClick={() => setStep(Steps.IMPRINT_MODE)}>
          {formatMessage("stepper.button.home")}
        </BSButton>
        <BSButton type={"reset"} onClick={() => setStep(Steps.SIGNATURE_MODE)}>
          {formatMessage("stepper.button.back")}
        </BSButton>
        <BSButton onClick={() => nextStep()}>
          {formatMessage("stepper.button.next")}
        </BSButton>
      </Stack>
    </Stack>
  );
}

export default SignatureBuilderStep;
