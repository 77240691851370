import React, { useContext, useRef } from "react";

import { StepperContext } from "../../contexts/stepper.context";
import BSImprintBuilder, {
  BSImprintBuilderMethods,
} from "../canvas/BSImprintBuilder";
import { Steps } from "../stepper.component";
import { Card, Stack, useTheme } from "@mui/material";
import BSButton from "../button.component";
import useFormatters from "../../hooks/use-formatters";

function ImprintBuilderStep() {
  const { setStep, setImprintPng } = useContext(StepperContext);
  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  const imprintBuilderRef = useRef<BSImprintBuilderMethods>();

  const nextStep = async () => {
    const imprintData = await imprintBuilderRef.current?.getImageData();

    if (imprintData) {
      setImprintPng(imprintData);
      setStep(Steps.SIGNATURE_MODE);
    }
  };

  return (
    <Stack direction="column" spacing={2} alignItems={"center"}>
      <h1 className="title">{formatMessage("stepper.builder.title")}</h1>
      <p className="text-body">{formatMessage("stepper.builder.subtitle")}</p>

      <Card
        style={{
          marginTop: 20,
          padding: "20px 20px 65px 20px",
          display: "flex",
          flexDirection: "column",
          gap: 20,
          width: "fit-content",
        }}
      >
        <BSImprintBuilder
          ref={imprintBuilderRef}
          width={795}
          height={470}
          style={{
            border: "1px solid",
            borderColor: "black",
            backgroundColor: "#ECEFF1",
          }}
        />
      </Card>

      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        paddingTop={2}
        paddingBottom={4}
      >
        <BSButton type={"reset"} onClick={() => setStep(Steps.IMPRINT_MODE)}>
          {formatMessage("stepper.button.home")}
        </BSButton>
        <BSButton type={"reset"} onClick={() => setStep(Steps.MODELS)}>
          {formatMessage("stepper.button.back")}
        </BSButton>
        <BSButton onClick={() => nextStep()}>
          {formatMessage("stepper.button.next")}
        </BSButton>
      </Stack>
    </Stack>
  );
}

export default ImprintBuilderStep;
