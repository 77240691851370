import React, { useContext } from "react";

import useFormatters from "../../hooks/use-formatters";
import { StepperContext } from "../../contexts/stepper.context";
import { Steps } from "../stepper.component";
import { Card, Stack, useTheme } from "@mui/material";
import BSButton from "../button.component";

import { ReactComponent as SuccessSVG } from "../../assets/Success.svg";

function ConfirmationStep() {
  const { setStep, mailUser } = useContext(StepperContext);

  const { formatMessage } = useFormatters();
  const { colors } = useTheme();

  return (
    <Stack
      direction="column"
      spacing={2}
      alignItems={"center"}
      marginTop={3}
      width={"100%"}
    >
      <Card className="card">
        <Stack
          direction="column"
          spacing={2}
          alignItems={"center"}
          paddingTop={1}
          paddingBottom={2}
        >
          <SuccessSVG />

          <h1 className="title">
            {formatMessage("stepper.confirmation.title")}
          </h1>

          <p className="text-body">
            {formatMessage("stepper.confirmation.subtitle")}
          </p>
          <p
            style={{
              fontSize: "22px",
              color: colors.black,
              wordBreak: "break-all",
            }}
          >
            {mailUser}
          </p>
          <p className="text-body">
            {formatMessage("stepper.confirmation.subtitle2")}
          </p>
        </Stack>
      </Card>

      <Stack direction={"row"} spacing={2} paddingTop={2} paddingBottom={4}>
        <BSButton onClick={() => setStep(Steps.IMPRINT_MODE)}>
          {formatMessage("stepper.button.home")}
        </BSButton>
      </Stack>
    </Stack>
  );
}

export default ConfirmationStep;
